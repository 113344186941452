// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'

on('submit', '.js-onboard-new-account', function (e) {
  const form = e.currentTarget
  if (!(form instanceof HTMLFormElement)) return

  const checkbox = form.querySelector<HTMLInputElement>('input[type="checkbox"]')
  if (!checkbox) return

  checkbox.disabled = true
})
