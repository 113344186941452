import {attr, controller, target, targets} from '@github/catalyst'
import {ready} from '@github-ui/document-ready'

type RunnerPlatform = 'linux-x64' | 'win-x64' | 'custom'
type RunnerImageSource = 'Curated' | 'Marketplace' | 'Custom'
type RunnerImageId = 'codespaces-prebuild' | 'ubuntu-latest' | 'windows-latest' | 'arm:avh_custom_runner:github'

@controller
class RunnerImageElement extends HTMLElement {
  @targets platforms: HTMLInputElement[]
  @targets imageVersions: RunnerImageVersionElement[]
  @targets selectedImageHints: RunnerSelectedImageHint[]
  @target customImageUriInput: HTMLInputElement
  @target machineSpecsDropdown: MachineSpecsDropdownElement

  async connectedCallback() {
    // trigger 'selectRunnerPlatform' when page become ready to update visibility of machine specs elements
    await ready
    this.selectRunnerPlatform()

    // prevent switching tabs on arrow key press. By default, it happens because input element is located inside "tablist"
    // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/tab_role#keyboard_interaction
    this.customImageUriInput.onkeydown = (e: Event) => e.stopPropagation()
  }

  selectRunnerPlatform() {
    const selectedPlatform = this.getSelectedPlatform()
    this.customImageUriInput.required = selectedPlatform === 'custom'

    const selectedImageVersion = this.getSelectedImageVersion(selectedPlatform)
    if (!selectedImageVersion) {
      return
    }

    this.updateSelectedImageHint(selectedImageVersion)
    if (this.machineSpecsDropdown) {
      this.machineSpecsDropdown.updateOptionsVisibility(selectedPlatform, selectedImageVersion)
    }
  }

  getSelectedPlatform(): RunnerPlatform {
    const selectedPlatform = this.platforms.find(platform => platform.checked)
    if (selectedPlatform) {
      return selectedPlatform.value as RunnerPlatform
    }

    return 'linux-x64'
  }

  getSelectedImageVersion(selectedPlatform: RunnerPlatform): RunnerImageVersionElement | null {
    if (this.imageVersions.length === 0) {
      return null
    }

    return (
      this.imageVersions.find(imageVer => imageVer.imagePlatform === selectedPlatform && imageVer.checked) ??
      this.imageVersions[0]!
    )
  }

  updateSelectedImageHint(selectedImageVersion: RunnerImageVersionElement) {
    // we have multiple image hint elements because it is separate element for every platform tab (Linux and Windows)
    for (const imageHintElement of this.selectedImageHints) {
      imageHintElement.renderContent(selectedImageVersion)
    }
  }
}

@controller
class MachineSpecsDropdownElement extends HTMLElement {
  @targets tabs: MachineSpecsTabElement[]
  @targets items: MachineSpecsItemElement[]
  @target tabsHeader: HTMLElement

  updateOptionsVisibility(platform: RunnerPlatform, imageVersion: RunnerImageVersionElement) {
    // refresh visibility of machine specs elements
    for (const item of this.items) {
      item.setVisibility(platform, imageVersion)
    }

    // only show tabs with visible items
    this.updateTabsVisibility()

    // if no item is selected or it's not visible, select the first visible item
    // the selected item might become hidden after the visibility refresh
    let selectedItem = this.getSelectedItem()
    if (!selectedItem || !selectedItem.visible) {
      const defaultItem = this.getFirstVisibleItem()
      defaultItem?.selectItem()
      selectedItem = defaultItem
    }

    // make sure the tab with selected item is selected
    if (selectedItem) {
      this.selectTabByType(selectedItem.runnerType)
    }
  }

  private updateTabsVisibility() {
    let numberVisibleTabs = 0
    for (const tab of this.tabs) {
      const shouldTabBeVisible = this.items.some(item => item.runnerType === tab.type && item.visible)
      numberVisibleTabs += shouldTabBeVisible ? 1 : 0
      tab.setVisibility(shouldTabBeVisible)
    }

    if (this.tabsHeader) {
      this.tabsHeader.hidden = numberVisibleTabs < 2
    }
  }

  private selectTabByType(type: string) {
    const targetTab = this.tabs.find(tab => tab.type === type)
    targetTab?.selectTab()
  }

  private getSelectedItem(): MachineSpecsItemElement | undefined {
    return this.items.find(item => item.checked)
  }

  private getFirstVisibleItem(): MachineSpecsItemElement | undefined {
    return this.items.find(item => item.visible)
  }
}

@controller
class MachineSpecsTabElement extends HTMLElement {
  @target clickArea: HTMLElement
  @attr type: string

  selectTab() {
    this.clickArea.click()
  }

  setVisibility(visible: boolean) {
    this.hidden = !visible
  }
}

@controller
class MachineSpecsItemElement extends HTMLElement {
  @target checkbox: HTMLInputElement

  static attrPrefix = ''
  @attr storageGb = 0
  @attr runnerType: string

  get checked(): boolean {
    return this.checkbox.checked
  }

  get visible(): boolean {
    return !this.hidden
  }

  selectItem() {
    this.checkbox.click()
  }

  setVisibility(platform: RunnerPlatform, imageVersion: RunnerImageVersionElement) {
    this.hidden = !this.shouldBeVisible(platform, imageVersion)
  }

  private shouldBeVisible(platform: RunnerPlatform, imageVersion: RunnerImageVersionElement): boolean {
    if (platform === 'win-x64' || platform === 'linux-x64') {
      if (this.runnerType === 'gpu_optimized' && imageVersion.imageSource === 'Curated') {
        // Curated images don't play well with GPU SKUs so hide them
        return false
      }
    }

    // if SKU has less disk space than required by selected image, hide it
    if (this.storageGb < imageVersion.sizeGb) {
      return false
    }

    return true
  }
}

@controller
class RunnerImageVersionElement extends HTMLElement {
  @target checkbox: HTMLInputElement

  static attrPrefix = ''
  @attr sizeGb = 0
  @attr imagePlatform: RunnerPlatform
  @attr imageSource: RunnerImageSource
  @attr imageId: RunnerImageId

  get checked(): boolean {
    return this.checkbox.checked
  }
}

@controller
class RunnerSelectedImageHint extends HTMLElement {
  renderContent(selectedImageVersion: RunnerImageVersionElement): void {
    this.innerHTML = this.getContent(selectedImageVersion)
  }

  private getContent(selectedImageVersion: RunnerImageVersionElement): string {
    let content = ''
    if (selectedImageVersion.imageSource === 'Curated') {
      const imageInfoUrl = this.getCuratedImageInfoUrl(selectedImageVersion)
      content += `
        <p class='text-small color-fg-muted'>
          GitHub images are kept up to date and secure, containing all the tools you need to get started building and testing your applications. <a href='${imageInfoUrl}'>Learn more about images.</a>
        </p>
      `

      if (selectedImageVersion.imageId === 'ubuntu-latest' || selectedImageVersion.imageId === 'windows-latest') {
        content += `
          <p class="text-small color-fg-muted">
            "Latest" tag matches with standard GitHub-hosted runners latest tag for the images. <a href='https://github.com/actions/runner-images#label-scheme'>Learn more about latest tags. </a>
          </p>
        `
      }
    } else if (selectedImageVersion.imageSource === 'Marketplace') {
      content += `
          <p class="text-small color-fg-muted">
          Partner images are provided by third parties. To find out more about how these are being kept up to date and supported by the partner as part of this closed beta, please reach out to your GitHub account team for the partner contact details.
          </p>
        `
    }

    return content
  }

  private getCuratedImageInfoUrl(selectedImageVersion: RunnerImageVersionElement): string {
    if (selectedImageVersion.imageId === 'codespaces-prebuild') {
      return 'https://github.com/github/codespaces'
    }

    return 'https://github.com/actions/runner-images/releases'
  }
}
